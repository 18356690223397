import React from 'react';
import tw from 'twin.macro';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

const Image = tw.img`pt-4 md:pt-6 w-full`;

export default () => (
  <ArticleLayout
    category="service"
    title="通用 Web 开发"
    subtitle="网站建设 / DevOps 实施 / 信息化系统 / SaaS"
  >
    <KeyPoint
      index="○"
      title="范围"
      content="基于现代网页浏览器实现品牌宣传或数字化转型；已有软件平台的优化、功能迭代与体验升级；企业内部信息化系统建设；向市场推广的特定模式软件产品。"
    />
    <KeyPoint
      index="○"
      title="案例：世茂集团"
      content="企划品牌智库 - 面向内部用户分享、展示和下载案例和参考材料，向物料供应商共享原始文档，沉淀品牌资产。"
    />
    <Image src="/showcase/shimao1.jpg" />
    <Image src="/showcase/shimao2.jpg" />
  </ArticleLayout>
);
